import { CentraSelection } from '@made-people/centra-models'
import {
  CartRequestContext,
  CartRequestParams,
  CartWorkerEventTypes,
  isCartRequestParams,
} from '../types/cart-worker-types'
import {
  createWorkerEvent,
  WorkerEventTopic,
  isWorkerEvent,
  isWorkerEventData,
  isWorkerEventAcknowledge,
  isWorkerEventRequest,
} from '~/types/worker-event'

/**
 * Setting up a DedicatedWorker
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Worker
 */
self.addEventListener('message', generateCartWorkerMessageHandler.call(self))

function generateCartWorkerMessageHandler (this: MessagePort | DedicatedWorkerGlobalScope) {
  let cartRequestParams: CartRequestParams | undefined
  let cart: CentraSelection | undefined
  let context: CartRequestContext | undefined
  let gotConnectedAcknowledge = false
  let gotSendCartRequest = false
  let gotSendContextRequest = false

  const ACKNOWLEDGE_INTERVAL = 10
  const trySendAcknowledge = () => {
    const payload = createWorkerEvent(
      WorkerEventTopic.Acknowledge,
      CartWorkerEventTypes.Connected
    )
    this.postMessage(payload)
    const interval = setInterval(() => {
      if (gotConnectedAcknowledge) {
        clearInterval(interval)
        return
      }
      this.postMessage(payload)
    }, ACKNOWLEDGE_INTERVAL)
  }
  trySendAcknowledge()

  const SEND_CART_INTERVAL = 2
  const startSendCartInterval = () => {
    const interval = setInterval(() => {
      if (!cart) {
        return
      }
      if (!gotSendCartRequest) {
        clearInterval(interval)
        return
      }
      this.postMessage(createWorkerEvent(
        WorkerEventTopic.Data,
        CartWorkerEventTypes.CartResponse,
        cart
      ))
    }, SEND_CART_INTERVAL)
  }

  const SEND_CONTEXT_INTERVAL = 2
  const startSendContextInterval = () => {
    const interval = setInterval(() => {
      if (!context) {
        return
      }
      if (!gotSendContextRequest) {
        clearInterval(interval)
        return
      }
      this.postMessage(createWorkerEvent(
        WorkerEventTopic.Data,
        CartWorkerEventTypes.CartRequestContext,
        context
      ))
    }, SEND_CONTEXT_INTERVAL)
  }

  const FETCH_CART_INTERVAL = 2
  const FETCH_CART_MAX_RETRIES = 2
  let fetchCartRetries = 0
  const tryFetchCart = () => {
    const interval = setInterval(() => {
      if (!cartRequestParams) {
        return
      }

      if (cart) {
        clearInterval(interval)
        return
      }

      ++fetchCartRetries
      if (fetchCartRetries > FETCH_CART_MAX_RETRIES) {
        clearInterval(interval)
        return
      }

      clearInterval(interval)
      fetchSelection(
        cartRequestParams,
        (err) => {
          this.postMessage(createWorkerEvent(WorkerEventTopic.Error, err))
          if (fetchCartRetries <= FETCH_CART_MAX_RETRIES) {
            tryFetchCart()
          }
        },
        (newContext) => {
          context = newContext
        }
      ).then((res) => {
        cart = res
      })
    }, FETCH_CART_INTERVAL)
  }
  tryFetchCart()

  return (event: MessageEvent<any>) => {
    const eventData = event.data
    if (!isWorkerEvent(eventData)) {
      return
    }

    if (
      isWorkerEventAcknowledge(eventData, CartWorkerEventTypes.Connected)
    ) {
      gotConnectedAcknowledge = true
      this.postMessage(
        createWorkerEvent(
          WorkerEventTopic.Request,
          CartWorkerEventTypes.CartRequestParams,
        )
      )
    } else if (
      isWorkerEventAcknowledge(eventData, CartWorkerEventTypes.CartResponse)
    ) {
      gotSendCartRequest = false
    } else if (
      isWorkerEventAcknowledge(eventData, CartWorkerEventTypes.CartRequestContext)
    ) {
      gotSendContextRequest = false
    } else if (
      isWorkerEventRequest(eventData, CartWorkerEventTypes.CartResponse)
    ) {
      if (typeof cart !== 'undefined') {
        this.postMessage(
          createWorkerEvent(
            WorkerEventTopic.Data,
            CartWorkerEventTypes.CartResponse,
            cart
          )
        )
      } else {
        gotSendCartRequest = true
        startSendCartInterval()
      }
    } else if (
      isWorkerEventRequest(eventData, CartWorkerEventTypes.CartRequestContext)
    ) {
      if (typeof context !== 'undefined') {
        this.postMessage(
          createWorkerEvent(
            WorkerEventTopic.Data,
            CartWorkerEventTypes.CartRequestContext,
            context
          )
        )
      } else {
        gotSendContextRequest = true
        startSendContextInterval()
      }
    } else if (
      isWorkerEventData(
        eventData,
        CartWorkerEventTypes.CartRequestParams,
        isCartRequestParams
      )
    ) {
      cartRequestParams = eventData.data
      this.postMessage(
        createWorkerEvent(
          WorkerEventTopic.Acknowledge,
          CartWorkerEventTypes.CartRequestParams
        )
      )
    }
  }
}

function fetchSelection (
  cartRequestParams: CartRequestParams,
  errorCallback: (error: any) => any,
  contexCallback: (context: CartRequestContext) => any
) {
  return fetch(cartRequestParams.requestUrl, {
    method: 'GET',
    headers: {
      ...cartRequestParams.headers,
    },
  })
    .then(async (res) => {
      if (res.headers.get('x-context-market')) {
        contexCallback({
          market: res.headers.get('x-context-market')!,
          language: res.headers.get('x-context-language')!,
          country: res.headers.get('x-context-country')!,
        })
      }

      const data = await res.json()

      if (res.status === 200) {
        return data as CentraSelection
      } else if (res.status === 204) {
        return undefined
      } else {
        errorCallback(data)
        return undefined
      }
    })
    .catch((e) => {
      if (e.response?.data) {
        errorCallback(e.response?.data || e)
      } else {
        errorCallback(e)
      }
      return undefined
    })
}
